.container {
    display: flex;
    align-items: center;
    height: 22px;
    border-radius: 11px;
    width: 40px;
    padding: 2px;
    background-color: #d8dee7;
    transition: background-color 0.4s;

    &:hover {
        cursor: pointer;
    }

    &.active {
        background-color: #0dc648;

        .circle {
            transform: translateX(100%);
        }
    }

    &.disabled {
        background-color: #b0bac7 !important;
    }
}

.circle {
    width: 18px;
    height: 18px;
    border-radius: 10px;
    background: white;
    transition: transform 0.4s;

    &.disabled {
        background-color: #e3e8ef;
    }
}
