@import 'variables';

.paymentErrorModal {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    display: none;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    padding: 0 40px;

    &.shown {
        display: flex;
    }

    .modal {
        background-color: white;
        padding: 14px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 14px;

        h4 {
            font-size: 18px;
            text-align: center;
            color: $title-color;
        }
        span {
            font-size: 15px;
            color: $content-color;
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 36px;
            border-radius: 6px;
            font-size: 15px;
            background-color: rgba(13, 178, 92, 0.15);
            color: rgba(13, 178, 92, 1);
            font-weight: bold;
            letter-spacing: 0.5px;
            margin-top: 20px;
        }
    }
}
