.container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 100%;

    .paymentForm {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-top: 20px;

        .info {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            color: #565454;

            .zelleEmail {
                font-size: 22px;
                font-weight: 700;
                text-align: center;
            }

            .companyName {
                color: #999;
                font-weight: 700;
                text-align: center;
                font-size: 16px;
            }
        }
    }

    footer {
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        text-align: center;
        gap: 20px;

        a {
            color: #0dc648;
        }

        .recurringExplanatory {
            font-size: 12px;
            margin-bottom: 10px;
        }
    }
}
