@import 'variables';

.inputContainer {
    position: relative;

    button {
        position: absolute;
        right: 4px;
        bottom: 12px;
        height: 28px;
        padding: 0 14px;
        background-color: #0dc648;
        color: white;
        border-radius: 4px;

        &:hover {
            cursor: pointer;
        }

        &:disabled {
            background-color: #ccc;
            pointer-events: none;
        }
    }
}

.couponApplied {
    background-color: #d8f6cb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px;
    height: 32px;
    border-radius: 6px;

    span,
    b {
        font-size: 14px;
        color: #128235;
    }
}

.clearCoupon:hover {
    cursor: pointer;
}

.errorMessage {
    font-size: 13px;
    color: $error-color;
    font-weight: 600;
}
