.container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    border-bottom: 1px solid #ccc;
    gap: 4px;

    .types {
        font-size: 14px;
        display: flex;
        gap: 4px;
        align-items: center;
    }

    .inputContainer {
        position: relative;
        display: flex;
        align-items: flex-end;
        flex: 1;
        height: 48px;

        .input {
            outline: none;
            border: none;
            background: none;
            padding: 0.4375em 0;
            font-size: 16px;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        label {
            position: absolute;
            font-size: 14px;
            top: 18px;
            transition: all 0.4s;
            transform-origin: left top;
            color: #999;

            &.up {
                transform: scale(0.75);
                top: -2px;
            }
        }
    }

    .typesModal {
        position: absolute;
        list-style: none;
        display: flex;
        flex-direction: column;
        background-color: white;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        overflow: hidden;
        z-index: 1;

        li {
            width: 40px;
            padding: 4px 6px;
            display: flex;
        }
    }
}
