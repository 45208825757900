@import 'variables';

@font-face {
    font-family: Lato;
    src: url(assets/fonts/lato/Lato-Light.ttf);
    font-weight: 300;
}

@font-face {
    font-family: Lato;
    src: url(assets/fonts/lato/Lato-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: Lato;
    src: url(assets/fonts/lato/Lato-Bold.ttf);
    font-weight: 700;
}

@font-face {
    font-family: Fira;
    src: url(assets/fonts/fira/FiraSans-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: Fira;
    src: url(assets/fonts/fira/FiraSans-Bold.ttf);
    font-weight: 700;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Lato, 'sans-serif';
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

button {
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
    }
}

/**
This is to remove the up-down arrows on inputs of type: number
 */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $title-color;
}

span,
p,
input,
label {
    color: $content-color;
}

.row {
    display: flex;
}

.column {
    display: flex;
    flex-direction: column;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}
