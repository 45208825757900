@import 'src/variables';

.card {
    position: absolute;
    width: 100%;
    min-width: 280px;
    max-width: 450px;
    visibility: hidden;
    gap: 20px;
    opacity: 0;
    top: 40px;
    z-index: 1;
    transition: top 0.8s, visibility 0.8s, opacity 0.8s;

    &.shown {
        top: 0;
        opacity: 1;
        visibility: visible;
        transition: top 0.8s, visibility 0.4s, opacity 0.4s;
    }

    &.rolledUp {
        height: 390px;
        overflow: hidden;
    }

    &.formCard {
        margin-top: 60px;
    }

    .cardTitle {
        color: #ccc;
        font-size: 18px;
        font-weight: 700;
        width: 100%;
        text-align: center;

        &.active {
            color: $title-color;
        }
    }

    .familyWarning {
        font-size: 12px;
        color: #ffad69;
        line-height: 1.1;
        text-align: center;
        margin-top: -20px;
    }

    .knownUserSelectionButtons {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 60px;

        button {
            min-height: 40px;
            flex: 1;
            background: none;
            border: none;
            outline: none;
            border-radius: 6px;
            font-weight: 600;

            &.primary {
                background-color: #0dc648;
                color: #fff;
            }
            &.secondary {
                background-color: #d7fde1;
                color: #0dc648;
            }
        }
    }

    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .formTitle {
        height: 1px;
        background-color: #666;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 12px 0;
        width: 100%;

        span {
            background: white;
            padding: 0 10px;
            font-size: 14px;
            color: #666;
        }
    }

    .errorButton {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: $error-color;
        margin-bottom: 10px;
        width: 100%;
        background-color: #ffeeee;
        padding: 4px 8px;
        border-radius: 4px;
        gap: 6px;
    }

    .addBeneficiaryForm {
        width: 100%;
        color: $green-text;
        font-weight: 400;
        font-size: 14px;
    }

    .duplicationModal {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.2);
        height: 100%;
        top: 0;

        .modal {
            display: flex;
            flex-direction: column;
            gap: 20px;
            background-color: white;
            padding: 16px;
            border-radius: 8px;
            margin: 0 40px;
            box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
            font-size: 14px;
            line-height: 20px;
        }
    }
}
