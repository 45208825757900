.view {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    background-color: white;
    align-items: center;
    flex-grow: 1;
    gap: 20px;
    position: relative;
}
