.item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    height: 80px;
    font-weight: 700;

    .name,
    .description {
        color: #999;
    }

    .description {
        font-weight: 400;
        font-size: 13px;
    }

    &:not(:last-child) {
        border-bottom: 1px solid #ddd;
    }

    &.active .name {
        color: #0dc648;

        &:hover {
            cursor: pointer;
        }
    }
}
