.container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;

    label {
        position: absolute;
        font-size: 14px;
        top: 18px;
        transition: all 0.4s;
        transform-origin: left top;
        color: #999;

        &.up {
            transform: scale(0.75);
            top: -2px;
        }
    }
}

.input {
    outline: none;
    border: none;
    border-bottom: 1px solid #ccc;
    height: 40px;
    background: none;
    padding: 0.4375em 0;
    font-size: 16px;
    width: 100%;

    &:disabled {
        background: #ddd;
    }
}
