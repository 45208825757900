#creditCardForm {
    display: flex;
    flex-direction: column;
    gap: 14px;
    flex: 1;

    .row {
        display: flex;
        justify-content: space-between;
        gap: 14px;

        .item {
            height: 100%;
        }
    }
    .column {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    input {
        border: 1px solid #e6e6e6;
        outline: none;
        flex: 1;
        min-width: 10px;
        padding: 0 8px;
        border-radius: 6px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02);
        font-size: 15px;
        min-height: 40px;
        height: 40px;
        max-height: 40px;
        width: 100%;

        &::placeholder {
            color: #a9aeb4;
        }
    }

    span.error {
        font-size: 13px;
        color: #ef7272;
        margin-top: 4px;
    }
}
