@import 'src/variables';

.container {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 940px;
    justify-content: center;
    gap: 100px;

    aside {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 340px;

        h4 {
            font-size: 30px;
        }

        span {
            font-size: 20px;
        }
    }

    section {
        display: flex;
        flex-direction: column;
        min-width: 320px;

        .cardsContainer {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 600px;
            padding-bottom: 40px;
        }

        .card {
            position: absolute;
            width: 100%;
            min-width: 280px;
            max-width: 450px;
            visibility: hidden;
            opacity: 0;
            top: 40px;
            z-index: 1;
            transition: top 0.8s, visibility 0.8s, opacity 0.8s;

            &.shown {
                top: 0;
                opacity: 1;
                visibility: visible;
                transition: top 0.8s, visibility 0.4s, opacity 0.4s;
            }

            &.formCard {
                margin-top: 60px;
            }

            &.paymentMethods {
                margin-top: 120px;
            }

            &.paymentForm {
                margin-top: 180px;
            }

            &.allDone {
                z-index: 999;
                gap: 50px;

                h4 {
                    font-size: 22px;
                }

                img {
                    width: 120px;
                }

                h4,
                p {
                    text-align: center;
                }
            }

            .cardTitle {
                color: #ccc;
                font-size: 18px;
                font-weight: 700;
                width: 100%;
                margin-bottom: 20px;
                text-align: center;

                &.active {
                    color: $title-color;
                }
            }
        }

        ul.paymentMethodsList {
            display: flex;
            flex-direction: column;
            list-style: none;
            width: 100%;
        }

        .explanatory {
            font-size: 13px;
            color: $content-color;
            margin-top: 10px;
        }
    }

    .planList {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 14px;
        // margin-bottom: 20px;

        .plan {
            width: 100%;
            padding: 12px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 6px;
            background-color: white;
            transition: background-color 0.4s;

            &:not(:last-child) {
                border-bottom: 1px solid #d8ded6;
            }

            &:hover {
                background-color: #0dc648;
                border: 1px solid #0dc648;
                cursor: pointer;

                span {
                    color: white;
                }
            }

            .name {
                color: $green-text;
                font-weight: 700;
            }

            .description {
                font-size: 13px;
                white-space: pre-wrap;
                overflow-wrap: break-word;
                padding: unset;
            }

            .price {
                font-size: 14px;
                color: $green-text;
                font-weight: 700;
                width: 50px;
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    .beneficiaryForm {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 14px;
    }
}

@media (max-width: 800px) {
    .container {
        flex-direction: column;
        gap: 40px;
        padding-top: 40px;
        padding-bottom: 40px;
        justify-content: flex-start;

        section {
            width: 100%;
            align-items: center;
        }

        .cardsContainer {
            max-width: 320px;
        }
    }
}
