.editButton {
    width: fit-content;
    height: 28px;
    padding: 0 10px;
    gap: 8px;
    border-radius: 14px;
    background-color: white;
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 20px;
    box-shadow: 10px 10px 40px rgba(55, 84, 170, 0.2), -20px -15px 30px #fff;

    &:hover {
        cursor: pointer;
    }

    &.shown {
        display: flex;
    }

    span {
        color: #0dc648;
    }
}
