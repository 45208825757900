.button {
    background: linear-gradient(270.61deg, #07bf41 8.39%, #0cee49 98.2%);
    height: 48px;
    border-radius: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        background: #ccc;
        pointer-events: none;
    }

    span {
        color: white;
        font-weight: 700;
        text-align: center;
        font-size: 18px;
    }
}
